import { create } from "@storybook/theming/create";

export default create({
  base: "dark",
  appBg: "#191919",
  appContentBg: "#191919",
  // appBorderRadius: 8,
  // colorPrimary: "#E3E0F4",
  // colorSecondary: "#6138F5",
  // inputBg: "#000",
  barBg: "#191919",
  // barTextColor: "#E3E0F4",
  // barSelectedColor: "#BAA8FA",

  brandTitle:
    '<img src="logo.png" alt="Prismerial UI" width="18px" class="css-l8z9zc"> Prismerial UI',
  brandTarget: "_self",
  fontBase:
    '"DM Sans", Helvetica, Avant Garde, Avantgarde, Century Gothic, CenturyGothic, AppleGothic, Geneva, Arial, sans-serif',
  fontCode: "monospace",
});
